import React, { useRef, useCallback, useEffect, useReducer } from "react"
import { graphql, navigate } from "gatsby"
import queryString from "query-string"
import Fuse from "fuse.js"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

import Seo from "../components/Seo"
import MainContent from "../components/MainContent"
import SearchResults from "../components/SearchResults"
import { Search as SearchIcon } from "../images/svg-icons"
import Header from '../components/Header';
import Footer from '../components/Footer';


const defaultState = {
  lastSearchTerm: undefined,
  query: undefined,
  results: null
};
const ACTION_SET = 'set';
const ACTION_RESET = 'reset';

function reducer(state, action) {
  switch (action.type) {
    case ACTION_SET:
      const newState = { ...state };

      [ 'query', 'results', 'lastSearchTerm' ].forEach(key => {
        if (action.hasOwnProperty(key)) {
          newState[key] = action[key];
        }
      });

      return newState;

    case ACTION_RESET:
      // bail out of update if current state already has default values
      if (
        state.lastSearchTerm === defaultState.lastSearchTerm &&
        state.query === defaultState.query &&
        state.results === defaultState.results
      ) {
        return state;
      }

      return { ...defaultState };

    default:
      return state;
  }
}

const Search = ({ location, data }) => {
  const fuse = useRef();

  useEffect(() => {
    const { allContentfulPage, allContentfulBlog } = data;
    const searchData = [];

    allContentfulPage.edges.forEach(({ node }) => {
      const { title, content, url, metaDescription } = node;

      searchData.push({
        type: 'page',
        title,
        shortSummary: null,
        summary: null,
        content: documentToPlainTextString(JSON.parse(content.raw)),
        url: url === 'index' ? '/' : `/${url}`,
        metaDescription
      });
    });

    allContentfulBlog.edges.forEach(({ node }) => {
      const { title, content, shortSummary, summary, slug, metaDescription } = node;

      searchData.push({
        type: 'blog',
        title,
        shortSummary: shortSummary && shortSummary.shortSummary ? shortSummary.shortSummary : null,
        summary: documentToPlainTextString(JSON.parse(summary.raw)),
        content: documentToPlainTextString(JSON.parse(content.raw)),
        url: `/blog/${slug}`,
        metaDescription
      });
    });

    fuse.current = new Fuse(searchData, {
      keys: [
        {
          name: 'title',
          weight: .3
        },
        {
          name: 'shortSummary',
          weight: .1
        },
        {
          name: 'summary',
          weight: .2
        },
        {
          name: 'content',
          weight: .3
        },
        {
          name: 'metaDescription',
          weight: .1
        }
      ]
    });
  }, [ data ]);

  const [ { query, lastSearchTerm, results }, dispatch ] = useReducer(reducer, defaultState);

  const handleSubmit = useCallback(ev => {
    if (query) {
      navigate(`/search/?q=${encodeURIComponent(query)}`);
    } else {
      navigate(`/search/`);
    }

    ev.preventDefault();
  }, [ query ]);

  const handleInputChange = useCallback(ev => {
    dispatch({ type: ACTION_SET, query: ev.target.value });
  }, []);

  useEffect(() => {
    const { q } = queryString.parse(location.search);

    if (!q) {
      dispatch({ type: ACTION_RESET });

      return;
    }

    dispatch({ type: ACTION_SET, query: q, lastSearchTerm: q, results: undefined });

    const timeout = setTimeout(() => {
      const results = fuse.current.search(q);

      dispatch({ type: ACTION_SET, results });
    }, 300 + Math.round(Math.random() * 150));

    return () => {
      clearTimeout(timeout);
    };
  }, [ location.search ]);

  return (
    <>
      <Header />
      <MainContent pathname={location.pathname}>
        <Seo title="Search Get eWallet" canonical={location.pathname} />
        <h1>Search</h1>
        <form className="augmentedInput search" action="/search/" method="GET" onSubmit={handleSubmit}>
          <input type="text" name="q" value={query || ''} onChange={handleInputChange} />
          <button type="submit" aria-label="Start search"><SearchIcon /></button>
        </form>
        {results !== null && <>
          <h2>Results for term <em className="textColorAccent">{lastSearchTerm}</em>:</h2>
          <SearchResults results={results} />
        </>}
      </MainContent>
      <Footer />
    </>
  );
};

export default Search

export const pageQuery = graphql`
  query {
    allContentfulPage {
      edges {
        node {
          title
          content {
            raw
          }
          url
          metaDescription
        }
      }
    }
    allContentfulBlog {
      edges {
        node {
          shortSummary {
            shortSummary
          }
          summary {
            raw
          }
          title
          content {
            raw
          }
          slug
          metaDescription
        }
      }
    }
  }
`;

import React from 'react';
import { Link } from 'gatsby';

import './SearchResults.sass';


const Item = React.memo(({ result }) => {
  const { type, url, title, shortSummary, content, metaDescription } = result;

  const typeText = type === 'page' ? 'Page' : 'Blog';
  let displaySummary = shortSummary;

  if (!displaySummary && metaDescription) {
    displaySummary = metaDescription;
  }

  if (!displaySummary && content) {
    displaySummary = content.substr(0, 200).trim();
  }

  return (
    <div className="result">
      <h2>{typeText} <span>/</span> <Link to={url}>{title}</Link></h2>
      <p className="">
        {displaySummary || <em>No description</em>}
      </p>
      <p className="actions"><Link className="button" to={url}>Read more</Link></p>
    </div>
  );
});

function SearchResults({ results }) {
  if (!Array.isArray(results)) {
    return (
      <div className="searchResults message">
        <p>Searching...</p>
      </div>
    );
  }

  if (results.length === 0) {
    return (
      <div className="searchResults message">
        <p>Sorry, there are no results for your search term.</p>
      </div>
    );
  }

  return (
    <div className="searchResults">
      {results.map(({ refIndex, item }) => (
        <Item key={refIndex} result={item} />
      ))}
    </div>
  );
}

export default React.memo(SearchResults);